
import { Vue, Component, Prop } from "vue-property-decorator";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";

interface Item {
  id: number;
  name: string;
}

interface FetchResult {
  items: Item[];
  total: number;
}

@Component
export default class SelectEntity extends Vue {
  @Prop({ type: String, required: true }) label!: string;
  @Prop({ type: Function, required: true }) onItemSelected!: (
    item: any
  ) => Promise<void>;
  @Prop({ type: Function, required: false }) filterFunction!: (
    item: any
  ) => boolean;
  @Prop({ type: Number, required: false }) initialSelectedId!: number | null;
  @Prop({ type: Object, default: () => ({}) }) autocompleteProps!: Record<
    string,
    any
  >;
  @Prop({ type: Number, default: 100 }) limit!: number;

  // Props for customizable fetching logic
  @Prop({ type: Function, required: true }) fetchItems!: (params: {
    page: number;
    limit: number;
    search?: string;
  }) => Promise<FetchResult>;
  @Prop({ type: Function, required: true }) fetchItemById!: (
    id: number
  ) => Promise<Item | null>;

  initialValue: Item | null = null;

  async mounted(): Promise<void> {
    if (this.initialSelectedId) {
      this.initialValue = await this.fetchItemById(this.initialSelectedId);
    }
  }

  async fetchFunction({
    page,
    limit,
    search
  }: {
    page: number;
    limit: number;
    search?: string;
  }): Promise<FetchResult> {
    return await this.fetchItems({ page, limit, search });
  }

  onFetchError(error: any): void {
    this.$notify({
      type: "error",
      text: getErrorMessageFromApiError(error)
    });
  }
}
