
import { Vue, Component, Prop } from "vue-property-decorator";
import SelectEntity from "../core/SelectEntity.vue";
import PartnerService from "@/services/partner-service";
import Partner from "../../types/partner";

@Component({
  components: {
    SelectEntity
  }
})
export default class SelectPartner extends Vue {
  @Prop({ type: String, default: "Parceiro" }) label!: string;
  @Prop({ type: Function, required: true }) onItemSelected!: (
    item: any
  ) => Promise<void>;
  @Prop({ type: Function, required: false }) filterFunction!: (
    item: any
  ) => boolean;
  @Prop({ type: Number, required: false }) initialSelectedId!: number | null;
  @Prop({ type: Object, default: () => ({}) }) autocompleteProps!: Record<
    string,
    any
  >;

  service = PartnerService.getInstance();

  async fetchPartners({
    page,
    limit,
    search
  }: {
    page: number;
    limit: number;
    search?: string;
  }): Promise<{
    items: Array<{ id: number; name: string; document: string }>;
    total: number;
  }> {
    const [error, data] = await this.service.listPartners({
      page,
      limit,
      name: search,
      sort: "name:ASC",
      loadRelations: false
    });

    if (error) {
      throw error;
    }

    return {
      items: data.data.map((item: Partner) => ({
        id: item.id,
        name: item.name,
        document: item.document
      })),
      total: data.total
    };
  }

  async fetchPartnerById(
    id: number
  ): Promise<{ id: number; name: string; document: string }> {
    const [error, partner] = await this.service.getPartnerById(id);

    if (error || !partner) {
      return null;
    }

    return { id: partner.id, name: partner.name, document: partner.document };
  }
}
